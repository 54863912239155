import React from 'react'
import { Link } from "react-router-dom";
import tooltip from "../../common/TooltipsStyle";
import { createStyles, Tooltip, WithStyles, withStyles } from '@material-ui/core';


const style = createStyles({
    ...tooltip});

interface IStyle extends WithStyles<typeof style> { }
const Malt: React.FC<IStyle> = (classes) => {
    

    return (
        <Tooltip
          id="malt_profile"
          title="My malt profile"
          placement="bottom"
          classes={{ tooltip: classes.classes.tooltip }}
        >
        <Link to="/malt"><img alt="malt profile" height="20" src={require("../../../assets/img/malt_logo_png.png")}/></Link>
        </Tooltip>
    )
}

export default withStyles(style)(Malt);