import React from 'react';
import { Icon } from '@iconify/react';
import visualStudio from '@iconify/icons-logos/visual-studio';
import { Tooltip, IconButton } from '@material-ui/core';
import { IIconProps } from './IconPropsType';

const VisualStudioIcon = (props: IIconProps) => {
    return (
        <Tooltip title="Visual Studio">
            <IconButton>
                <Icon width={props.width} height={props.height} icon={visualStudio} />
            </IconButton>
        </Tooltip>
    )
}

export default VisualStudioIcon;