import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "../../common/GridContainer";
import GridItem from "../../common/GridItem";
import Card from "../../common/Card";
import CardBody from "../../common/CardBody";

import aboutContentStyle from "./AboutContentStyle";

import profile from "../../../assets/img/aboutimage.png";
import { WithStyles } from "@material-ui/styles";
import CardFooter from "../../common/CardFooter";
import FreelancerMap from "./FreelancerMap";
import Malt from "./Malt";

interface IAboutContent extends WithStyles<typeof aboutContentStyle> { }
const AboutContent: React.FC<IAboutContent> = ({ classes }) => {
  const imageClasses = classNames(
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>About Me</h2>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <img src={profile} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Leopold Kühschelm
                  <br />
                <small className={classes.smallTitle}>Software Architect And Engineer</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Hi, I am Leo, software architect and engineer. Coming from Austria, I am now calling Berlin my home since 2012. At the age of 14 I started coding, with my first programming language being Turbo Pascal.
</p>
                  <p className={classes.description}>
                  After school I studied coputer science at the University of Technology (TU) in Vienna with specialization in computer graphics and network communication protocols. I graduated in 2006 and started working at s IT Solutions Spardat Gmbh as a software engineer in the department of application integration management for more than five years. Back then, I designed authentication, communication and service management solutions for a wide range of platforms, e.g. Windows, Linux, Unix and z/OS Mainframe, in different programming languages, such as C, Java, Cobol or Natural.
</p>
                  <p className={classes.description}>After those years at s IT, I joined Accenture. Working on several projects, I learned a lot about customers communication and how to manage a project with an offshore team in the Philippines. However, after seven months I wanted to do more hands-on programming myself and started working at IVU Traffic Technologies AG, where I have been for 7 years now.</p>
                  <p className={classes.description}>IVU produces software for planning and operating public transport. I worked as software engineer for two years and as a software architect on the timetable planning part of the ivu.plan software suite for more than five years.</p>
                  <p className={classes.description}>In my point of view, it’s absolutely essential for a software architect to code as well, in order to gain a new perspective on the product. Using the design and architecture regularly also makes one aware of possible pitfalls and fallacies. Mostly, I have coded in C++ and Java in recent years.</p>
                  <p className={classes.description}>Besides my daily work I have always enjoyed learning something new, so almost two years ago I started to delve into the field of cloud computing. I am mainly interested in cloud native architectures and how to migrate or enhance a system by using serverless patterns.</p>
                  <p className={classes.description}>What I like most about my work are the different challenges in finding and designing good technical solutions for problems and learning something new every day. This is what motivates me most and therefore, I have decided to take a new direction and gain work experience as a freelance software engineer and architect.</p>
                  <p className={classes.description}>In my free time, I love hiking, orienteering and traveling, especially by train, because it is a unique way of getting to know a country and its residents.</p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <FreelancerMap/><div className={classes.divider}/><Malt/>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}


export default withStyles(aboutContentStyle)(AboutContent);
