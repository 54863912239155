import React, { useState } from 'react'
import { Grid, Menu, Segment } from 'semantic-ui-react'
import AdminContent, { AdminSection } from './AdminContent';



const AdminDashboard = () => {
  const [activeItem, setActiveItem] = useState(AdminSection.groups);

  const handleItemClick = (name:AdminSection ) => setActiveItem(name)

  return (
      <Grid>
        <Grid.Column width={4}>
          <Menu fluid vertical tabular>
            <Menu.Item
              name='Groups'
              active={activeItem === AdminSection.groups}
              onClick={(e, data) => { handleItemClick(AdminSection.groups)}}
            />
            <Menu.Item
              name='Tags'
              active={activeItem === AdminSection.tags}
              onClick={(e, data) => { handleItemClick(AdminSection.tags)}}
            />
            <Menu.Item
              name='Authors'
              active={activeItem === AdminSection.authors}
              onClick={(e, data) => { handleItemClick(AdminSection.authors)}}
            />
            <Menu.Item
              name='Posts'
              active={activeItem === AdminSection.posts}
              onClick={(e, data) => { handleItemClick(AdminSection.posts)}}
            />
          </Menu>
        </Grid.Column>

        <Grid.Column stretched width={12}>
          <Segment>
              <AdminContent section={activeItem}></AdminContent>
          </Segment>
        </Grid.Column>
      </Grid>
    )
}

export default AdminDashboard;