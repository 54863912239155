import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import GridContainer from "../../common/GridContainer";
import GridItem from "../../common/GridItem";
import CustomInput from "../../common/CustomInput";
import Button from "../../common/Button";
import contactStyle from "./ContactContentStyle";
import { API } from "aws-amplify";
import { ReCaptcha } from 'react-recaptcha-v3';
import { awsconfig } from '../../../AwsConfig';
import Card from "../../common/Card";
import CardBody from "../../common/CardBody";


interface IContactContentProps {
    title: string;
}
interface IContactContentStyle extends WithStyles<typeof contactStyle> { }
type ContactData = {
    user_name: string;
    user_address: string;
    subject: string;
    message: string;
    reCaptchaResponse: string;
}
type IContactContent = IContactContentStyle & IContactContentProps;
const ContactContent: React.FC<IContactContent> = (props: IContactContent) => {
    const { classes, title } = props;

    const {
        register,
        handleSubmit,
        setValue,
        errors,
        setError,
        triggerValidation
    } = useForm<ContactData>();

    const recaptchaRef = React.useRef(null);
    const formRef = React.useRef(null);
    useEffect(() => {
        register({ name: "user_name" }, { required: true });
        register({ name: "user_address" }, { required: true });
        register({ name: "subject" }, { required: true });
        register({ name: "message" }, { required: true });
        register({ name: "reCaptchaResponse" }, { required: true });
    }, [register]);

    const [status, setStatus] = useState("");

    useEffect(() => {
        console.log("status changed");
        formRef.current.reset();
    }, [formRef, status]);

    const sendEmail = (data: ContactData) => {
        recaptchaRef.current.execute();
        const payload = JSON.stringify(data);
        const request = {
            headers: {
                "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Amz-User-Agent",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
            },
            body: payload
        };
        console.log("sending: " + JSON.stringify(request))
        API.post("sendContactRest", "/contactRequest", request)
            .then(response => {
                setStatus("Message sent successfully");
            })
            .catch(error => {
                setStatus("Sorry we could not deliver your message due to technical problems, please try again later!");
            });
    }

    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card plain>
                        <CardBody>
                            <h2 className={classes.title}>{title}</h2>
                            <p className={classes.description}>
                                You are welcome to share ideas, thoughts or ask questions - I usually respond within a couple of hours.
                    </p>
                            <form ref={formRef} onSubmit={handleSubmit(sendEmail)}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Your Name"
                                            id="user_name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={
                                                {
                                                    onChange: async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                        setValue("user_name", event.target.value);
                                                        await triggerValidation("user_name");
                                                    },
                                                    error: errors.user_name ? true : false

                                                }
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Your Email"
                                            id="user_address"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={
                                                {
                                                    onChange: async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                        setValue("user_address", event.target.value);
                                                        await triggerValidation("user_address");
                                                    },
                                                    error: errors.user_address ? true : false
                                                }
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Subject"
                                            id="subject"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={
                                                {
                                                    onChange: async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                        setValue("subject", event.target.value);
                                                        await triggerValidation("subject");
                                                    },
                                                    error: errors.subject ? true : false
                                                }
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Your Message"
                                            id="message"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                multiline: true,
                                                rows: 5,
                                                onChange: async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                    setValue("message", event.target.value);
                                                    await triggerValidation("message");
                                                },
                                                error: errors.message ? true : false
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <ReCaptcha
                                            ref={recaptchaRef}
                                            sitekey={awsconfig.recaptchaKey}
                                            action='submit'
                                            size="normal"
                                            data-theme="dark"
                                            render="explicit"
                                            badge="inline"
                                            verifyCallback={(value) => { setValue("reCaptchaResponse", value); }}
                                            onExpired={() => {
                                                setValue("reCaptchaResponse", null);
                                                setError("reCaptchaResponse", "reCaptcha expired");
                                            }}
                                            onErrored={() => {
                                                setValue("reCaptchaResponse", null);
                                                setError(
                                                    "reCaptchaResponse",
                                                    "There has been a problem with the reCaptcha"
                                                );
                                            }}
                                        >

                                        </ReCaptcha>
                                    </GridItem>
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        className={classes.textCenter}
                                    >
                                        <Button color="primary" type="submit">Send Message</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p className={classes.description}>
                                            {status}
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>
        </div>
    );
}

export default withStyles(contactStyle)(ContactContent);
