import { title } from "../../common/TitleStyle";
import { createStyles } from "@material-ui/core";

const redirectContentStyle = createStyles({
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    textAlign: "center",
    color: "#888888"
  },
  leftalign: {
    textAlign: "left"
  }
});

export default redirectContentStyle;