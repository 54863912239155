import React from'react';
import SiteFramework from '../SiteFramework';
import RedirectToExternalContent, {RedirectToExternalProps} from './RedirectToExternalContent';

const RedirectToExternal: React.FC<RedirectToExternalProps> = (props: RedirectToExternalProps) => {
    return (
        <SiteFramework content={<RedirectToExternalContent title={props.title} url={props.url}/>}></SiteFramework>
    )
}

export default RedirectToExternal;