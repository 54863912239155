import React from'react';
import SiteFramework from '../SiteFramework';
import ContactContent from './ContactContent';

const Contact = () => {
    return (
        <SiteFramework content={<ContactContent title="Contact me:"/>}></SiteFramework>
    )
}

export default Contact;