import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from 'semantic-ui-react'

export type CreateTagFormData = {
    name: string;
}

type CreateFormProps = {
    onSubmit: (data: CreateTagFormData) => void;
    onCancel: () => void;
}

const CreateTagForm:React.SFC<CreateFormProps> = (props: CreateFormProps) => {


    const {
        register,
        errors,
        handleSubmit,
        setValue,
        triggerValidation
    } = useForm<CreateTagFormData>();

    useEffect(() => {
        register({ name: "name" }, { required: true });
    }, [register]);

    return (

        <Form onSubmit={handleSubmit(props.onSubmit)}>

            <Form.Input
                name="Name"
                label="Name"
                placeholder="Tag Name"
                onChange={async (e, data) => {
                    console.log(data);
                    setValue("name", data.value);
                    await triggerValidation("name");
                }}
                error={errors.name ? true : false}
            />
            <Button type="submit">Create Tag</Button>
            <Button type="button" onClick={() => {props.onCancel();}}>Cancel</Button> 
        </Form>

    );
}

export default CreateTagForm;