import React, { useEffect } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3'
import AppRouter from './components/routing/AppRouter';

import Amplify from 'aws-amplify';
//import { Hub, Auth } from 'aws-amplify';
//import { AmplifySignOut } from '@aws-amplify/ui-react';
//import LogIn from './components/views/auth/LogIn';
import {awsconfig} from './AwsConfig';



Amplify.configure(awsconfig);

function App() {
  
  //const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    /*Auth.currentAuthenticatedUser().then(user => { setAuthenticated(true); }).catch(err => { setAuthenticated(false) });
    Hub.listen('auth', ({ payload }) => {
      console.log('A new auth event has happened: ', payload)
      if (payload.event === 'signIn') {
        setAuthenticated(true);
      }
      if (payload.event === 'signOut') {
        setAuthenticated(false);
      }
    })*/
    loadReCaptcha(awsconfig.recaptchaKey, () => {});
  }, []);

  //if (authenticated) {
    return (<div>
      <AppRouter></AppRouter>
    </div>);
  //}

  //return <LogIn />;
}

export default App;
