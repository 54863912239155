import React from 'react';
import About from "../about/AboutContent"
import Offerings from '../offerings/OfferingsContent';

import ContactContent from '../contacts/ContactContent';
import IntroContent from '../intro/IntroContent';

const LandingContent = () => {
    return (
        <React.Fragment>
            <IntroContent/>
            <Offerings />
            <About/>
            <ContactContent title="Contact me:"/>
        </React.Fragment>
    )
}

export default LandingContent;