import React from'react';
import SiteFramework from '../SiteFramework';
import NotFoundContent from './NotFoundContent';

const NotFound = () => {
    return (
        <SiteFramework content={<NotFoundContent/>}></SiteFramework>
    )
}

export default NotFound;