
import imagesStyle from "../../common/ImageStyle";
import { createStyles } from "@material-ui/core";
import { title } from "../../common/TitleStyle";
import {defaultFont} from "../../common/FontStyle";

const introContentStyle = createStyles({
  section: {
    padding: "10px",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  smallTitle: {
    color: "#6c757d",
  },
  content: {
    padding: "10px",
    wordWrap: "break-word",
    flex: "1 1 auto",
    ...defaultFont,
    fontSize: "16px",
    fontWeight: 400,
  },
  description: {
    textAlign: "center",
    color: "#999999",
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  }
});

export default introContentStyle;