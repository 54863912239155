import React, { useState } from "react";
import GroupList, { GroupData } from "./GroupList";


const GroupAdmin = () => {
    let initialGroupData: Array<GroupData> = [
        {name: 'test1'},
        {name: 'test2'}
    ];

    const [groupData, setGroupData] = useState<Array<GroupData>>(initialGroupData);

    const onAddedHandler = (data: GroupData) => {
        console.log(data.name + 'added')
        const newGroups = [...groupData, {name: data.name}];
        setGroupData(newGroups);
    };

    const onRemovedHandler = (items: Array<GroupData>) => {
        const newGroups = groupData.filter((data: GroupData) => {
            return items.find((item: GroupData) => item.name === data.name) === undefined;
        });
        setGroupData(newGroups);
    }

    return (
        <GroupList groups={groupData} onAddGroup={onAddedHandler} onRemoveGroup={onRemovedHandler}></GroupList>
    )
}

export default GroupAdmin;