import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Table, Icon, Checkbox } from "semantic-ui-react";
import CreateGroupForm, { CreateGroupFormData } from "./CreateGroup";

export type GroupData = {
    name: string;
}

type GroupListProps = {
    groups: Array<GroupData>;
    onAddGroup: (data: GroupData) => void;
    onRemoveGroup: (data: Array<GroupData>) => void;
};

type GroupListItem = {
    data: GroupData;
    active: boolean;
}

enum SortDirection {
    ascending,
    descending
}

type SortState = {
    column: string;
    direction: SortDirection;
}

const GroupList: React.SFC<GroupListProps> = (props: GroupListProps) => {
    const initialState: Array<GroupListItem> = props.groups.map((data: GroupData) => { return { data: { ...data }, active: false } });
    const [items, setItems] = useState(initialState);
    const [showAddGroup, setShowAddGroup] = useState(false);
    const [sortState, setSortState] = useState<SortState>({ column: "name", direction: SortDirection.ascending });

    const inputRef = useRef();
    
    const sortItems = useCallback(
        (items: Array<GroupListItem>, sortState) => {
            return items.sort((a: GroupListItem, b: GroupListItem) => {
                if (sortState.column === "name") {

                    const comp = a.data.name.localeCompare(b.data.name);
                    if (sortState.direction === SortDirection.ascending) {
                        return -comp;
                    }
                    return comp;
                }
                return 0;
            })

        },
        [],
    )


    useEffect(() => {
        const newState: Array<GroupListItem> = props.groups.map((data: GroupData) => { return { data: { ...data }, active: false } });
        const newItems = sortItems(newState, sortState);
        setItems(newItems);
    }, [props.groups, sortItems, sortState])

    useEffect(() => {
        console.log("sortState or items changed");
        const newItems = sortItems(items, sortState);

        if (newItems.length !== items.length || !newItems.every((value, index) => value === items[index])) {
            console.log("order of items changed", newItems);

            setItems(newItems);
        }

    }, [sortState, items, sortItems])

    const onCheckBoxChanged = (name: string, value: boolean) => {
        console.log('check state changed: ' + name + 'value: ' + value)
        const newItems: Array<GroupListItem> = items.map((item) => {
            if (item.data.name === name) {
                const data = { ...item.data };
                return { data: data, active: value };
            }
            return item;
        });
        setItems(newItems);
        console.log(newItems);
    }

    const onCancelCreateGroup = () => {
        setShowAddGroup(false);
    }

    const onSaveGroup = (data: CreateGroupFormData) => {
        const addedGroup: GroupData = { name: data.name };
        setShowAddGroup(false);
        props.onAddGroup(addedGroup);

    };
    const onAddGroup = () => {
        setShowAddGroup(true);
    }

    const onSort = (column: string) => {
        const newSortState = { ...sortState };
        if (sortState.column === column) {
            if (sortState.direction === SortDirection.ascending) {
                newSortState.direction = SortDirection.descending;
            }
            else {
                newSortState.direction = SortDirection.ascending;
            }
        }
        else {
            newSortState.column = column;
            newSortState.direction = SortDirection.ascending;
        }
        console.log(newSortState);
        setSortState(newSortState);
    }

    const onRemoveGroups = () => {
        const itemsToRemove = items.filter((item: GroupListItem) => { return item.active === true; });
        const dataToRemove = itemsToRemove.map(item => item.data);
        props.onRemoveGroup(dataToRemove);
    }

    const getSortString = () => {
        if (sortState.direction === SortDirection.descending) return "descending";
        return "ascending";
    }

    const groupItems = items.map((data: GroupListItem) => {
        return (
            <Table.Row key={data.data.name}>
                <Table.Cell collapsing>
                    <Checkbox ref={inputRef} onChange={(e, dataCB) => {
                        const value: boolean = dataCB.checked ? true : false;
                        onCheckBoxChanged(data.data.name, value);
                    }}
                        checked={data.active}
                    />
                </Table.Cell>
                <Table.Cell>
                    {data.data.name}
                </Table.Cell>
            </Table.Row>);
    });

    return (
        showAddGroup ?
            <CreateGroupForm onCancel={onCancelCreateGroup} onSubmit={onSaveGroup} />
            :
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell
                            sorted={getSortString()}
                            onClick={() => { onSort('name') }}
                        >Name</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {groupItems}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>

                        <Table.HeaderCell colSpan='2'>
                            <Button
                                floated='right'
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={() => { onAddGroup(); }}
                            >
                                <Icon name='group' /> Add Group</Button>
                            <Button floated='left'
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={() => { onRemoveGroups(); }}
                            >
                                <Icon name='group' />Remove Group</Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
    )
}

export default GroupList;