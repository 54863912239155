import { createStyles } from "@material-ui/core";

export const iconStyle = createStyles({
    iconWrapper: {
        float: "left",
        marginTop: "24px",
        marginRight: "10px"
      },
      icon: {
        width: "36px",
        height: "36px"
      },
      iconWrapperVertical: {
        float: "none"
      },
      iconVertical: {
        width: "61px",
        height: "61px"
      }
});