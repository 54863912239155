import React from 'react';
import { Icon } from '@iconify/react';
import icon from '@iconify/icons-logos/intellij-idea';
import { Tooltip, IconButton } from '@material-ui/core';
import { IIconProps } from './IconPropsType';

const IntelliJIcon = (props: IIconProps) => {
    return (
        <Tooltip title="IntelliJ">
            <IconButton>
                <Icon width={props.width} height={props.height} icon={icon} />
            </IconButton>
        </Tooltip>
    )
}

export default IntelliJIcon;