/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
  import { createStyles } from "@material-ui/core";
  import { defaultFont} from "./FontStyle";
  const cardHeaderStyle = createStyles({
    cardHeader: {
      borderRadius: "3px",
      padding: "1rem 15px",
      marginLeft: "15px",
      marginRight: "15px",
      marginTop: "-30px",
      border: "0",
      marginBottom: "0"
    },
    cardHeaderPlain: {
      marginLeft: "0px",
      marginRight: "0px"
    },
    primaryBoxShadow: {
        boxShadow:
          "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)"
      },
      infoBoxShadow: {
        boxShadow:
          "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
      },
      successBoxShadow: {
        boxShadow:
          "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
      },
      warningBoxShadow: {
        boxShadow:
          "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
      },
      dangerBoxShadow: {
        boxShadow:
          "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
      },
      roseBoxShadow: {
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
      },
      
      warningCardHeader: {
        color: "#fff",
        background: "linear-gradient(60deg, #ffa726, #fb8c00)",
        boxShadow:
          "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
      },
      successCardHeader: {
        color: "#fff",
        background: "linear-gradient(60deg, #66bb6a, #43a047)",
        boxShadow:
          "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
      },
      dangerCardHeader: {
        color: "#fff",
        background: "linear-gradient(60deg, #ef5350, #e53935)",
        boxShadow:
          "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
      },
      infoCardHeader: {
        color: "#fff",
        background: "linear-gradient(60deg, #26c6da, #00acc1)",
        boxShadow:
          "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
      },
      primaryCardHeader: {
        color: "#fff",
        background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
        boxShadow:
          "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)"
      },
      roseCardHeader: {
        color: "#fff",
        background: "linear-gradient(60deg, #ec407a, #d81b60)",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
      },
      cardActions: {
        margin: "0 20px 10px",
        paddingTop: "10px",
        borderTop: "1px solid #eeeeee",
        height: "auto",
        ...defaultFont
      },
  });
  
  export default cardHeaderStyle;
  