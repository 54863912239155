import React from 'react';
import { Icon } from '@iconify/react';
import cplusplus from '@iconify/icons-logos/c-plusplus'
import { Tooltip, IconButton } from '@material-ui/core';
import { IIconProps } from './IconPropsType';

const CPlusPlusIcon = (props: IIconProps) => {
    return (
        <Tooltip title="C++">
            <IconButton>
                <Icon width={props.width} height={props.height} icon={cplusplus} />
            </IconButton>
        </Tooltip>
    )
}

export default CPlusPlusIcon;