import React from 'react';
import classNames from "classnames";

import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from "@material-ui/styles";

import Header from "../common/Header";
import HeaderLinks from "../common/HeaderLinks";
import Footer from "../common/Footer";
import GridContainer from "../common/GridContainer";
import GridItem from "../common/GridItem";
import CookieConsent from "react-cookie-consent";
import Parallax from "../common/Parallax";

import siteFrameworkStyle from "./SiteFrameworkStyle";

// Sections for this page

//import Typical from 'react-typical';
import { Link } from 'react-router-dom';
interface ISiteFrameWorkProps {
    content: any;
}
interface ISiteFrameWorkStyle extends WithStyles<typeof siteFrameworkStyle> { }

type ISiteFrameWork = ISiteFrameWorkProps & ISiteFrameWorkStyle;

const SiteFramework: React.FC<ISiteFrameWork> = ({ classes, ...rest }) => {
    const dashboardRoutes = [];
    //const phrases = ["I help you to create and evolve your software through efficient architecture", 3000,
    //    "I help you in finding a lean engineering process which suits your needs", 3000,
    //    "I help you during implementation of your project", 3000]; // Required
    //const interval = 5000; // The time to wait before rendering the next string
    //const typistProps = {} // Props that are passed to the react-typist component
    //const loopPhrases = true // Set to false to stop at last phrase
    const { content } = rest;
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="devARC - Developing Architectures"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 300,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter small={true} image={require("../../assets/img/api-landing.png")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h1 className={classes.title}>Design And Evolve Software with efficient Architecture</h1>
                            <h3>
                            “Organizations which design systems […] are constrained to produce designs which are copies of the communication structures of these organizations.”<br/>
– Melvin E. Conway

                            </h3>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    {content}
                </div>
            </div>
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="cookieConsent"
                buttonClasses="btn btn-primary"
                containerClasses="alert alert-warning col-lg-12"
                contentClasses="text-capitalize"
                style={{ background: "#add2f7" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience. <Link to="/privacy">More on Privacy Policy</Link> and <Link to="/legal">Terms of Use</Link>
            </CookieConsent>
        </div>


    );



};

export default withStyles(siteFrameworkStyle)(SiteFramework);