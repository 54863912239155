import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';

import style from './LegalNoticeStyle';
import GridContainer from '../../common/GridContainer';
import GridItem from '../../common/GridItem';
import { Link } from 'react-router-dom';
import Card from '../../common/Card';
import CardBody from '../../common/CardBody';
import CardHeader from '../../common/CardHeader';

interface ILegalNotice extends WithStyles<typeof style> { }

const LegalNotice: React.FC<ILegalNotice> = ({ classes }) => {
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card plain>
                        <CardHeader>
                    <h2 className={classes.title}>Legal Notice</h2>
                    <h3 className={classes.title}>Last updated: 2020-05-10</h3>
                    </CardHeader>
                    <CardBody>
                        <h3 className={classes.subtitle}>Information according to § 5 TMG</h3>
                        <p className={classes.description}>devARC IT Solutions GmbH<br/>c/o Factory Works GmbH<br/>Lohmühlenstraße 65<br/>12435 Berlin
                        </p>
                        <h4 className={classes.subtitle}>Represented by: </h4>
                        <p className={classes.description}>
                        Leopold K&uuml;hschelm
                        </p>
                        <h4 className={classes.subtitle}>Contact:</h4>
                        <p className={classes.description}>
                        E-Mail: <a href='mailto:leo@devarc.io'>leo@devarc.io</a>
                        </p>
                        
                        <h4 className={classes.subtitle}>Register entry: </h4>
                        <p className={classes.description}>
                        Register court: Berlin-Charlottenburg<br />
                        Registernumber: [company in founding process - updated asap]<br />
                        </p>
                        <h4 className={classes.subtitle}>Sales tax identification number: </h4>
                        <p className={classes.description}>
                        Sales tax identification number according to §27a sales tax law: [company in founding process - updated asap]</p>
                        <h4 className={classes.subtitle}>Business identification number: </h4>
                        <p className={classes.description}>
                        [company in founding process - updated asap]
                        </p>
                        <h4 className={classes.subtitle}>Responsible for the content according to § 55 Abs. 2 RStV:</h4>
                        <p className={classes.description}>
                        Leopold K&uuml;hschelm<br />
                        Kranoldstr. 15<br />
                        12051 Berlin<br />
                        </p>
                        </CardBody>
                        </Card>
                        <Card plain>
                        <CardHeader>
                        <h2 className={classes.title}>Website Terms and Conditions of Use</h2>
                        </CardHeader>
                        <CardBody>
                        <h3 className={classes.title}>1. Terms</h3>

                        <p className={classes.description}>
                            By accessing this Website, accessible from https://devArc.io, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws.
                            If you disagree with any of these terms, you are prohibited from accessing this site.
                            The materials contained in this Website are protected by copyright and trade mark law.
                        </p>
                        <p className={classes.description}>
                        This site is built upon a theme from <a href="https://www.creative-tim.com">Creative Tim</a>
                        </p>
                        <p className={classes.description}>
                        These Terms of Service has been created with the help of the <a href="https://www.termsofservicegenerator.net">Terms of Service Generator</a> and the <a href="https://www.termsconditionsexample.com">Terms & Conditions Example</a>.
                        </p>
                        <p className={classes.description}>
                        The site uses <a href="https://material.io/resources/icons/?style=baseline">Material-UI icons</a> published under <a href="https://www.apache.org/licenses/LICENSE-2.0.html">Apache 2.0 License</a>  
                        </p>
                        <p className={classes.description}>
                        The site uses <a href="https://material.io/resources/icons/?style=baseline">Iconify icon collection</a>:<br/>
                        <a href="https://iconify.design/icon-sets/logos/">Logos SVG Icons</a> licensed under <a href="https://raw.githubusercontent.com/gilbarbara/logos/master/LICENSE.txt">Creative Commons 1.0</a><br/>
                        </p>
                        <p className={classes.description}>
                        The site uses the Spring Boot logo which is a trademark of the <a href="https://spring.io/projects">Spring Project</a>
                        </p>
                        <p className={classes.description}>
                        The header image by <a href="https://stock.adobe.com/de/contributor/201945596/putilov-denis?load_type=author&prev_url=detail">putilov_denis</a> is licensed through <a href="https://stock.adobe.com/de/license-terms">Abobe Stock Standard License</a>
                        </p>

                        <h3 className={classes.title}>2. Use License</h3>

                        <p className={classes.description}>
                            Permission is granted to temporarily download one copy of the materials on devArc's Website for personal, non-commercial transitory viewing only.
                            This is the grant of a license, not a transfer of title, and under this license you may not:
</p>

                        <ul className={classes.descriptionList}>
                            <li>modify or copy the materials;</li>
                            <li>use the materials for any commercial purpose or for any public display;</li>
                            <li>attempt to reverse engineer any software contained on devArc's Website;</li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                        </ul>

                        <p className={classes.description}>
                            This will let devArc to terminate upon violations of any of these restrictions.
                            Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
                        </p>

                        <h3 className={classes.title}>3. Disclaimer</h3>

                        <p className={classes.description}>
                            All the materials on Testsite’s Website are provided "as is".
                            devArc makes no warranties, may it be expressed or implied, therefore negates all other warranties.
Furthermore, devArc does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

                        <h3 className={classes.title}>4. Limitations</h3>

                        <p className={classes.description}>devArc or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on devArc’s Website, even if devArc or an authorize representative of this website has been notified, orally or written, of the possibility of such damage.
Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

                        <h3 className={classes.title}>5. Revisions and Errata</h3>

                        <p className={classes.description}>
                            The materials appearing on devArc’s Website may include technical, typographical, or photographic errors.
                            devArc will not promise that any of the materials in this Website are accurate, complete, or current.
                            devArc may change the materials contained on its Website at any time without notice.
                            devArc does not make any commitment to update the materials.
</p>

                        <h3 className={classes.title}>6. Links</h3>

                        <p className={classes.description}>
                            devArc has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site.
The presence of any link does not imply endorsement by devArc of the site. The use of any linked website is at the user’s own risk.</p>

                        <h3 className={classes.title}>7. Site Terms of Use Modifications</h3>

                        <p className={classes.description}>
                            devArc may revise these Terms of Use for its Website at any time without prior notice.
By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

                        <h3 className={classes.title}>8. Your Privacy</h3>

                        <p className={classes.description}>Please read <Link to="/privacy">our Privacy Policy</Link>.</p>

                        <h3 className={classes.title}>9. Governing Law</h3>

                        <p className={classes.description}>Any claim related to devArc's website shall be governed by the laws of de without regards to its conflict of law provisions.</p>
                        </CardBody>
                        </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default withStyles(style)(LegalNotice);