import React from'react';
import OfferingsContent from './OfferingsContent';
import SiteFramework from '../SiteFramework';
import ContactContent from '../contacts/ContactContent';

const Offerings = () => {
    return (
    <SiteFramework content={<div><OfferingsContent/><ContactContent title={"Any questions or opinions to share?"}/></div>}></SiteFramework>
    )
}

export default Offerings;