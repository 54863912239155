import React from 'react'
import { Link } from "react-router-dom";
import tooltip from "../../common/TooltipsStyle";
import { WithStyles, createStyles, withStyles, Tooltip } from '@material-ui/core';

const style = createStyles({
    ...tooltip});

interface IStyle extends WithStyles<typeof style> { }
const FreelancerMap: React.FC<IStyle> = (classes) => {
    return (
        <Tooltip
          id="freelancermap_profile"
          title="My freelancermap profile"
          placement="bottom"
          classes={{ tooltip: classes.classes.tooltip }}
        >
        <Link to="/freelancermap"><img alt="freelancermap" src="https://www.freelancermap.de/images/widgets/button/freelancermap.png"/></Link>
        </Tooltip>
    )
}

export default withStyles(style)(FreelancerMap);