import { title } from "../../common/TitleStyle";
import { createStyles } from "@material-ui/core";

const privacyContentStyle = createStyles({
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#888888",
    textAlign: "center"
  },
  descriptionList: {
    color: "#888888",
    textAlign: "justify"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  icon: {
    width: "60px",
    height: "60px"
  }
});

export default privacyContentStyle;