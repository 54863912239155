import React from 'react';
import redirectContentStyle from './RedirectToExternalStyles';
import { WithStyles, withStyles} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';


export type RedirectToExternalProps = {
    url: string;
    title: string;
}

interface IRedirectToExternalStyle extends WithStyles<typeof redirectContentStyle>{};

type IRedirectToExternalContent = IRedirectToExternalStyle & RedirectToExternalProps;

const RedirectToExternalContent: React.FC<IRedirectToExternalContent> = ({classes, url, title}) => {
    return (
        <div className={classes.section}>
        <h2 className={classes.title}>Redirecting to: {title}</h2>
        <p className={classes.description}>Attention: You are redirected to the external site: {url}</p>
        <p className={classes.description}>Do you want to proceed?</p>
        <Button href={url} color="primary">Yes</Button><Link component={Button} color="secondary" to="/">No (*)</Link>
        <p className={classes.description}>* leads you back to the landing page</p>
        </div>
    )
}

export default withStyles(redirectContentStyle)(RedirectToExternalContent);