import React from "react";
import { Tooltip, Hidden, Button, IconButton, withStyles, WithStyles } from "@material-ui/core";
import headerLinksStyle from "./HeaderLinksStyle";
import { Link } from "react-router-dom";

type HeaderButtonProps = {
    id: string;
    title: string;
    link: string;
    icon: any;
}

interface IHeaderButtonStyle extends WithStyles<typeof headerLinksStyle> {}

type IHeaderButton = IHeaderButtonStyle & HeaderButtonProps;

const HeaderButton: React.FC<IHeaderButton> = ({ classes, id, title, link, icon }) => {
    const headerLink = link; 
    return (
        <Tooltip
          id={id}
          title={title}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <div>
          <Hidden mdUp implementation="js">
          <Button
            component={Link}
            to={headerLink}
            rel="noopener noreferrer"
            className={classes.navLink}
            startIcon={icon}
          >
            {title}
          </Button>
          </Hidden>
          <Hidden mdDown implementation="js">
          <IconButton
            component={Link}
            to={headerLink}
            rel="noopener noreferrer"
            className={classes.navLink}
          >
            {icon}
          </IconButton>
          </Hidden>
          </div>
        </Tooltip>
    )
}

export default withStyles(headerLinksStyle)(HeaderButton);