import React from 'react';
import { Icon } from '@iconify/react';
import git from '@iconify/icons-logos/git-icon'

const GitIcon = () => {
    return (
        <Icon width={60} height={60} icon={git}/>
    )
}

export default GitIcon;