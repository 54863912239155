import React from 'react';
import SiteFramework from '../SiteFramework';
import PrivacyStatementContent from './PrivacyStatementContent';


const PrivacyStatement = () => {
    return (
        <SiteFramework content={<PrivacyStatementContent></PrivacyStatementContent>}></SiteFramework>
    );
}

export default PrivacyStatement;