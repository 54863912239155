import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Table, Icon, Checkbox } from "semantic-ui-react";
import CreateTagForm, { CreateTagFormData } from "./CreateTag";

export type TagData = {
    name: string;
}

type TagListProps = {
    tags: Array<TagData>;
    onAddTag: (data: TagData) => void;
    onRemoveTag: (data: Array<TagData>) => void;
};

type TagListItem = {
    data: TagData;
    active: boolean;
}

enum SortDirection {
    ascending,
    descending
}

type TagSortState = {
    column: string;
    direction: SortDirection;
}

const TagList: React.SFC<TagListProps> = (props: TagListProps) => {
    const initialState: Array<TagListItem> = props.tags.map((data: TagData) => { return { data: { ...data }, active: false } });
    const [items, setItems] = useState(initialState);
    const [showAddTag, setShowAddTag] = useState(false);
    const [sortTagState, setTagSortState] = useState<TagSortState>({ column: "name", direction: SortDirection.ascending });
    
    const inputRef = useRef();

    const sortItems = useCallback(
        (items: Array<TagListItem>, sortState) => {
            return items.sort((a: TagListItem, b: TagListItem) => {
                if (sortState.column === "name") {

                    const comp = a.data.name.localeCompare(b.data.name);
                    if (sortState.direction === SortDirection.ascending) {
                        return -comp;
                    }
                    return comp;
                }
                return 0;
            })

        },
        [],
    )


    useEffect(() => {
        const newState: Array<TagListItem> = props.tags.map((data: TagData) => { return { data: { ...data }, active: false } });
        const newItems = sortItems(newState, sortTagState);
        setItems(newItems);
    }, [props.tags, sortItems, sortTagState])

    useEffect(() => {
        console.log("sortState or items changed");
        const newItems = sortItems(items, sortTagState);

        if (newItems.length !== items.length || !newItems.every((value, index) => value === items[index])) {
            console.log("order of items changed", newItems);

            setItems(newItems);
        }

    }, [sortTagState, items, sortItems])

    const onCheckBoxChanged = (name: string, value: boolean) => {
        console.log('check state changed: ' + name + 'value: ' + value)
        const newItems: Array<TagListItem> = items.map((item) => {
            if (item.data.name === name) {
                const data = { ...item.data };
                return { data: data, active: value };
            }
            return item;
        });
        setItems(newItems);
        console.log(newItems);
    }

    const onCancelCreateTag = () => {
        setShowAddTag(false);
    }

    const onSaveTag = (data: CreateTagFormData) => {
        const addedTag: TagData = { name: data.name };
        setShowAddTag(false);
        props.onAddTag(addedTag);

    };
    const onAddTag = () => {
        setShowAddTag(true);
    }

    const onSort = (column: string) => {
        const newSortState = { ...sortTagState };
        if (sortTagState.column === column) {
            if (sortTagState.direction === SortDirection.ascending) {
                newSortState.direction = SortDirection.descending;
            }
            else {
                newSortState.direction = SortDirection.ascending;
            }
        }
        else {
            newSortState.column = column;
            newSortState.direction = SortDirection.ascending;
        }
        console.log(newSortState);
        setTagSortState(newSortState);
    }

    const onRemoveTags = () => {
        const itemsToRemove = items.filter((item: TagListItem) => { return item.active === true; });
        const dataToRemove = itemsToRemove.map(item => item.data);
        props.onRemoveTag(dataToRemove);
    }

    const getSortString = () => {
        if (sortTagState.direction === SortDirection.descending) return "descending";
        return "ascending";
    }

    const TagItems = items.map((data: TagListItem) => {
        return (
            <Table.Row key={data.data.name}>
                <Table.Cell collapsing>
                    <Checkbox ref={inputRef} onChange={(e, dataCB) => {
                        const value: boolean = dataCB.checked ? true : false;
                        onCheckBoxChanged(data.data.name, value);
                    }}
                        checked={data.active}
                    />
                </Table.Cell>
                <Table.Cell>
                    {data.data.name}
                </Table.Cell>
            </Table.Row>);
    });

    return (
        showAddTag ?
            <CreateTagForm onCancel={onCancelCreateTag} onSubmit={onSaveTag} />
            :
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell
                            sorted={getSortString()}
                            onClick={() => { onSort('name') }}
                        >Name</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {TagItems}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>

                        <Table.HeaderCell colSpan='2'>
                            <Button
                                floated='right'
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={() => { onAddTag(); }}
                            >
                                <Icon name='tag' /> Add Tag</Button>
                            <Button floated='left'
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={() => { onRemoveTags(); }}
                            >
                                <Icon name='tag' />Remove Tag</Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
    )
}

export default TagList;