import React, { useState } from "react";
import TagList, { TagData  } from "./TagList";


const TagAdmin = () => {
    let initialTagData: Array<TagData> = [
        {name: 'tag1'},
        {name: 'tag2'}
    ];

    const [tagData, setTagData] = useState<Array<TagData>>(initialTagData);

    const onAddedHandler = (data: TagData) => {
        console.log(data.name + 'added')
        const newTags = [...tagData, {name: data.name}];
        setTagData(newTags);
    };

    const onRemovedHandler = (items: Array<TagData>) => {
        const newTags = tagData.filter((data: TagData) => {
            return items.find((item: TagData) => item.name === data.name) === undefined;
        });
        setTagData(newTags);
    }

    return (
        <TagList tags={tagData} onAddTag={onAddedHandler} onRemoveTag={onRemovedHandler}></TagList>
    )
}

export default TagAdmin;