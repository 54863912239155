import React from 'react';
import SiteFramework from '../SiteFramework';
import LandingContent from './LandingContent';


const LandingPage = () => {
    return (
        <SiteFramework content={<LandingContent></LandingContent>}></SiteFramework>
    );
};

export default LandingPage;