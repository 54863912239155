import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';

import privacyStatementContentStyle from './PrivacyStatementContentStyle';
import GridContainer from '../../common/GridContainer';
import GridItem from '../../common/GridItem';

import PrivacyIcon from '@material-ui/icons/Fingerprint';
import CommunicationsIcon from '@material-ui/icons/Telegram';
import CookieIcon from '@material-ui/icons/Cake';
import SecurityIcon from '@material-ui/icons/SecurityTwoTone';
import ChangesIcon from '@material-ui/icons/TrackChangesTwoTone';
import CollectionIcon from '@material-ui/icons/ArchiveTwoTone';

import InfoArea from '../../common/InfoArea';
import CardBody from '../../common/CardBody';
import Card from '../../common/Card';
import CardHeader from '../../common/CardHeader';

interface IPrivacyStatementContent extends WithStyles<typeof privacyStatementContentStyle> {}
const PrivacyStatementContent: React.FC<IPrivacyStatementContent> = (props: IPrivacyStatementContent) => {
    const {classes} = props;
    return (
        <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <Card plain>
              <CardHeader>
            <h2 className={classes.title}>Privacy Policy</h2>
            <h3 className={classes.title}>Last updated: 2020-05-10</h3>
            </CardHeader>
            <CardBody>
            <h5 className={classes.description}>
            My Company devARC ("us", "we", or "our") operates https://devArc.io (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.<br/>
        We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.
            </h5>
            </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Information Collection And Use"
                content={<p className={classes.description}>While using my Site, we may ask you to provide us certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name, address or email address ("Personal Information").The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide (if you are interested please read the security section, there is a secure way of e-mail communication with us) </p>}
                icon={CollectionIcon}
                iconColor="warning"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Log Data"
                content={<p className={classes.description}>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer&apos;s Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>}
                icon={PrivacyIcon}
                iconColor="danger"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Communications"
                content={<p className={classes.description}>We DO NOT use your personal data to send you newsletter, marketing or promotional materials and other information. We contact you only on your request.</p>}
                icon={CommunicationsIcon}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Cookies"
                content={<p className={classes.description}>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer&apos;s hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>}
                icon={CookieIcon}
                iconColor="warning"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Security"
                content={<p className={classes.description}>The security of your Personal Information is important to us. This site always uses https, if you reach it by any other means please do not go any further, it is not this Site. Your email communication can be made end to end secure by the help of <a href="https://tutanota.com" target="_blank" rel="noopener noreferrer">Tutanota</a>. At least your email and attachments are stored encrypted on the server. Always remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we are eager to use any means to protect your Personal Information, we cannot guarantee its absolute security.</p>}
                icon={SecurityIcon}
                iconColor="danger"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Changes To This Privacy Policy"
                content={<p className={classes.description}>This Privacy Policy is effective as of (2020-05-10) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. If we make any changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on this website.</p>}
                icon={ChangesIcon}
                iconColor="info"
                vertical
              />
            </GridItem>
        </GridContainer>
        <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <InfoArea
              title="Your data protection rights"
              content={<div>
              <p className={classes.description}>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                <ul className={classes.descriptionList}>
                <li>The right to access – You have the right to request copies of your personal data</li>
                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
                <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
                </ul>
                </div>}
              icon={SecurityIcon}
              iconColor="success"
              vertical
              />
            </GridItem>
        </GridContainer>
        </div>
    );
}

export default withStyles(privacyStatementContentStyle)(PrivacyStatementContent);