import React from'react';
import AboutContent from './AboutContent';
import SiteFramework from '../SiteFramework';

const About = () => {
    return (
        <SiteFramework content={<AboutContent/>}></SiteFramework>
    )
}

export default About;