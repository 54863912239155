export const awsconfig = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
      "aws_cloud_logic_custom": [
          {
              "name": "sendContactRest",
              "endpoint": process.env.REACT_APP_URL_API_ENDPOINT,
              "region": process.env.REACT_APP_AWS_REGION,
          }
      ],
      "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_POOL_ID,
      "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
      "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
      "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
      "oauth": {},
      "recaptchaKey": process.env.REACT_APP_RECAPTCHA_API_KEY
  };