
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import CloudDoneIcon from "@material-ui/icons/CloudDoneTwoTone";
import SoftwareEngineeringIcon from "@material-ui/icons/LaptopMacTwoTone";
import ArchitectureIcon from "@material-ui/icons/ExtensionTwoTone";
import CPlusPlusIcon from "../icons/CPlusPlusIcon";
import JavaIcon from "../icons/JavaIcon";
import PythonIcon from "../icons/PythonIcon";
import TypeScriptIcon from "../icons/TypeScriptIcon";
import GitIcon from "../icons/GitIcon";
import QTIcon from "../icons/QTIcon";
import SpringBootIcon from "../icons/SpringBootIcon";
import ReactJSIcon from "../icons/ReactIcon";
import AWSCertificate from "./AWSCertificate";

// core components
import GridContainer from "../../common/GridContainer";
import GridItem from "../../common/GridItem";
import InfoArea from "../../common/InfoArea";

import style from "./OfferingsStyles";
import { WithStyles } from "@material-ui/styles";
import JenkinsIcon from "../icons/JenkinsIcon";
import DockerIcon from "../icons/DockerIcon";
import IntelliJIcon from "../icons/IntelliJIcon";
import { IIconProps } from "../icons/IconPropsType";
import VisualStudioIcon from "../icons/VisualStudioIcon";
import GraphQLIcon from "../icons/GraphQLIcon";
import DynamoDBIcon from "../icons/DynamoDBIcon";

interface IOfferings extends WithStyles<typeof style> { }
const OfferingsContent: React.FC<IOfferings> = ({ classes }) => {
  const iconProps: IIconProps = {
    width: 40,
    height: 40
  }
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>How I may help you to implement your projects and ideas:</h2>
          <h5 className={classes.description}>
            </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Cloud Computing"
              content={
                <div className={classes.content}>
              <p className={classes.description}>Modern cloud computing strategies and offers let us leverage and connect cloud services with on-premises workloads. As a certified AWS Cloud Architect Associate and AWS Cloud Developer Associate I can:
              <br />&bull; help you to find the right cloud strategy for your business
              <br />&bull; design the cloud workflow
              <br />&bull; help you during implementation and deployment</p>
              <AWSCertificate id="aws_developer_associate" title="AWS Developer Associate Certificate" img={require('../../../assets/img/aws-certified-developer-associate.png')} link="https://www.youracclaim.com/badges/1394e01a-4320-40c2-9313-f55c396ae424/public_url"/>
              <AWSCertificate id="aws_solution_architect_associate" title="AWS Solutions Architect Associate Certificate" img={require('../../../assets/img/aws-certified-solutions-architect-associate.png')} link="https://www.youracclaim.com/badges/e6e06023-1e50-4d04-bfa0-e72b161c3981/public_url"/>
              </div>}
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Engineering And Design"
              content={
                <div className={classes.content}>
                  <p className={classes.description}>With more than 10 years of experience as software engineer and solutions analyst in the finance and public transport industry, I can help you analyse, design and implement your ideas and projects. <br />
                  I am experienced in following programming languages, frameworks and tools:<br/> </p>
                  <div>
                  <JavaIcon {...iconProps} />
                  <CPlusPlusIcon {...iconProps} />
                  <TypeScriptIcon {...iconProps} />
                  <PythonIcon {...iconProps} />
                  </div>
                  <div>
                  <SpringBootIcon {...iconProps} />
                  <GraphQLIcon {...iconProps} />
                  <ReactJSIcon {...iconProps} />
                  <QTIcon {...iconProps} />
                  </div>
                  <div>
                  <JenkinsIcon {...iconProps} />
                  <DockerIcon {...iconProps} />
                  <DynamoDBIcon {...iconProps} />
                  <IntelliJIcon {...iconProps} />
                  <VisualStudioIcon {...iconProps} />
                  </div>
                  <p className={classes.description}><br/><br/></p>
                </div>}
              icon={SoftwareEngineeringIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Architecture"
              content={
                <div className={classes.content}>
              <p className={classes.description}>Most projects of a certain size are guided by a particular technical idea, hence there are always some kind of patterns to follow. That’s what architecture is about.<br/>
              It is essential to find a sort of architecture that fits your needs and fulfils your future demands for the product. Most times there might be no ideal architecture, but my knowledge about software implementation and architectural patterns may help you to develop or evolve your architecture in the best possible way.
              In my opinion, there is no right or wrong regarding the question whether one should design a monolith or use microservices. Using a well-structured monolith might even be superior in certain contexts. The ultimate choice always depends on your goals.<br/>
              I usually focus on understanding the problem domain and what you would like to achieve with your product. In a next step, I  identify the bounded contexts in your system and design value objects, entities and services with clean and stable interfaces.
              </p>
              </div>}
              icon={ArchitectureIcon}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InfoArea
              title="Git Consulting"
              content={
                <div className={classes.content}>
                  <p className={classes.description}>Being a long-term Git user, I can help you with my hands-on experience. Moreover, I have gained experience in migrating a big codebase (about 10M LOC) from SVN to a Git monorepo. Therefore, I can offer you a thorough understanding of Git internals and knowledge on existing Git branching strategies, which also enables me to design a developing and branching workflow which fits your needs.</p>
                  </div>}
              icon={GitIcon}
              iconColor="primary"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(style)(OfferingsContent);
