import React from 'react';
import notFoundStyle from './NotFoundStyles';
import { WithStyles, withStyles} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';


export type RedirectToExternalProps = {
    url: string;
    title: string;
}

interface INotFoundStyle extends WithStyles<typeof notFoundStyle>{};
const NotFoundContent: React.FC<INotFoundStyle> = ({classes}) => {
    return (
        <div className={classes.section}>
        <h2 className={classes.title}>Sorry something went wrong!</h2>
        <p className={classes.description}>The page you requested was not found</p>
        <Link component={Button} color="primary" to="/">Proceed to Landing Page</Link>
        </div>
    )
}

export default withStyles(notFoundStyle)(NotFoundContent);