import { title } from "../../common/TitleStyle";
import { createStyles } from "@material-ui/core";
import { defaultFont } from "../../common/FontStyle";

const offeringsStyle = createStyles({
  section: {
    padding: "10px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  content: {
    padding: "10px",
    wordWrap: "break-word",
    flex: "1 1 auto",
    ...defaultFont
  },
  description: {
    textAlign: "justify",
    color: "#888888"
  },
  leftalign: {
    textAlign: "left"
  },
  inlineicon: {
    float: "left",
    width: "30px",
    height: "30px"
  }
});

export default offeringsStyle;