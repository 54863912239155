import React from 'react';
import AdminDashboard from '../views/admin/AdminDashboard';
import LandingPage from '../views/landingPage/Landing';
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import About from '../views/about/About';
import Contact from '../views/contacts/Contact';
import Offerings from '../views/offerings/Offerings';
import PrivacyStatement from '../views/privacy/PrivacyStatement';
import LegalNotice from '../views/legal/LegalNotice';
import RedirectToExternal from '../views/redirect/RedirectToExternal';
import NotFound from '../views/404/NotFound';

const AppRouter = () => {
  var hist = createBrowserHistory();

    return (
        <Router history={hist}>
        <Switch>
          <Route exact path="/" component={LandingPage}/>
          <Route path="/offerings" component={Offerings}/>
          <Route path="/profile" component={About}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/admin" component={AdminDashboard}/>
          <Route path="/legal" component={LegalNotice}/>
          <Route path="/privacy" component={PrivacyStatement}/>
          <Route path="/linkedin" component={() => <RedirectToExternal title="LinkedIn" url="https://www.linkedin.com/in/leopold-kuehschelm/"/>} />
          <Route path="/github" component={() => <RedirectToExternal title="Github" url="https://github.com/leopold2410"/>} />
          <Route path="/freelancermap" component={() => <RedirectToExternal title="Freelancermap Profile" url="https://www.freelancermap.de/freelancer-verzeichnis/profile/entwicklung/223851-profil-leopold-kuehschelm-software-ingenieur-software-architekt.html)"/>} />
          <Route path="/malt" component={()=> <RedirectToExternal title="Malt Profile" url="https://www.malt.de/profile/leopoldkuhschelm"/>}/>
          <Route component={NotFound}/>
        </Switch>
    </Router>
    )
}

export default AppRouter;