/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

  import { createStyles } from "@material-ui/core";
  import {title} from "./TitleStyle";
import { primaryColor, warningColor, dangerColor, successColor, infoColor, roseColor, grayColor } from "./ColorStyle";
import { defaultFont } from "./FontStyle";
import { iconStyle } from "./IconStyle";

  
  const infoStyle = createStyles({
    infoArea: {
      maxWidth: "360px",
      margin: "0 auto",
      padding: "10px",
      flex: "1 1 auto"
    },
    primary: {
      color: primaryColor
    },
    warning: {
      color: warningColor
    },
    danger: {
      color: dangerColor
    },
    success: {
      color: successColor
    },
    info: {
      color: infoColor
    },
    rose: {
      color: roseColor
    },
    gray: {
      color: grayColor
    },
    descriptionWrapper: {
      color: grayColor,
      overflow: "hidden"
    },
    title,
    description: {
      color: grayColor,
      overflow: "hidden",
      marginTop: "0px",
      wordWrap: "break-word",
      ...defaultFont
    },
    ...iconStyle
  });
  
  export default infoStyle;
  