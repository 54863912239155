
import imagesStyle from "../../common/ImageStyle";
import { createStyles } from "@material-ui/core";
import { title, cardTitle } from "../../common/TitleStyle";
import { grayColor } from "../../common/ColorStyle";
import { defaultFont } from "../../common/FontStyle";

const aboutContentStyle = createStyles({
  section: {
    padding: "10px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: grayColor,
    textAlign: "justify",
    ...defaultFont,
    fontSize: "16px"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  },
  divider: {
    width:"5px",
    height:"auto",
    display:"inline-block"
  }
});

export default aboutContentStyle;
