import * as React from "react";

function SpringBootLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 240 200" {...props}>
      <path
        d="M76.1 9.4c-6.8 2.4-10.4 6.6-18.7 21.1-.6 1.1-2.1 3.7-3.2 5.7-1.2 2.1-3.1 5.4-4.2 7.3-2.7 4.8-4.6 7.9-13 22.5-4 6.9-8.1 14.1-9.2 16-1.1 1.9-2.3 3.9-2.7 4.5C23.6 88.9 22 94.8 22 98c0 3.2 1.6 9.1 3.1 11.5.4.5 1.6 2.6 2.7 4.5s5.2 9.1 9.2 16c8.4 14.6 10.3 17.7 13 22.5 1.1 1.9 3 5.2 4.2 7.2 1.1 2.1 2.6 4.7 3.2 5.8 6.2 10.9 8.3 14 11.7 16.8 2.1 1.9 5.6 3.9 7.8 4.5 5.4 1.7 84.8 1.7 90.2 0 6.4-1.9 11.2-6.9 18.1-19 3.4-6.1 7.4-12.9 8.7-15.2 1.3-2.3 3.2-5.5 4.1-7.1.9-1.7 3.1-5.4 4.9-8.3 1.7-2.9 3.1-5.5 3.1-5.7 0-.2 2.2-4 4.9-8.4 7.5-12.2 11.1-20.3 11.1-25.1 0-4.8-3.6-12.9-11.1-25.1-2.7-4.4-4.9-8.2-4.9-8.4 0-.2-1.4-2.8-3.1-5.7-1.8-2.9-4-6.7-4.9-8.3-.9-1.7-2.8-4.8-4.1-7.1-1.3-2.3-5.3-9.1-8.7-15.2-6.9-12.1-11.7-17.1-18.1-19-5.5-1.7-86.3-1.5-91 .2zm51.6 30.8c2.3 2.1 2.3 2.2 2.3 29.8v27.7l-2.5 2.3c-3.5 3.3-9 3.3-11.6 0-1.7-2.3-1.9-4.6-1.9-29.9 0-26.8 0-27.5 2.2-29.8 2.8-3 8.3-3.1 11.5-.1zM96.8 50c2.7 1.7 3.2 2.6 3.2 5.7 0 3.3-.8 4.6-5.8 9.5-8.9 8.7-12.5 17.1-12.5 29.3 0 11.8 2.9 19.2 11 27.6 8.5 8.9 17 12.4 29.8 12.3 8.1-.1 9.8-.5 15.9-3.4 14.4-7 23.4-21.1 23.6-36.9 0-9.8-4.8-20.8-12.3-28.3-7.2-7.1-8.2-9-6.3-12.6.8-1.5 2.6-3.4 3.9-4.1 2.3-1.2 3-1.1 7 1.2C162 54.8 170 65.6 174 77.1c3.4 9.2 3.4 25.7.1 34.8-7 19.3-20.9 31.7-41.1 36.7-28.5 7-59.3-13.3-65.5-43.1-2.8-13.2-1.3-22.7 5.7-37.1 3.2-6.5 10.7-15.1 16-18.4 4-2.4 3.6-2.4 7.6 0z"
        fill="#02ad12"
      />
    </svg>
  );
}

export default SpringBootLogo;