import React from 'react';
import GroupAdmin from './GroupAdmin';
import TagAdmin from './TagAdmin';

export enum AdminSection {
    groups,
    tags,
    authors,
    posts
}

type AdminContentProps = {
    section: AdminSection
}

const AdminContent: React.SFC<AdminContentProps> = (props: AdminContentProps) => {
    switch (props.section) {
        case AdminSection.groups:
            return (<GroupAdmin />);
        case AdminSection.tags:
            return (<TagAdmin/>);
        case AdminSection.authors:
            return (<div></div>);
        case AdminSection.posts:
            return (<div></div>);
        default:
            return (<div></div>);
    }
}

export default AdminContent;