import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import graphql from '@iconify/icons-logos/graphql';
import { IIconProps } from './IconPropsType';

const GraphQLIcon = (props: IIconProps) => {
    return (
        <Tooltip title="GraphQL">
            <IconButton>
            <Icon width={props.width} height={props.height} icon={graphql}/>
            </IconButton>
        </Tooltip>
    ) 
}

export default GraphQLIcon;