import React from 'react'
import tooltip from "../../common/TooltipsStyle";
import { WithStyles, createStyles, withStyles, Tooltip } from '@material-ui/core';

const style = createStyles({
    ...tooltip});

interface IStyle extends WithStyles<typeof style> { }

type CertificateProps = {
    img: any,
    link: string,
    title: string,
    id: string
}
const AWSCertificate: React.FC<IStyle & CertificateProps> = ({classes, ...props}) => {
    return (
        <Tooltip
          id={props.id}
          title={props.title}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
        <a href={props.link} target="_blank" rel="noopener noreferrer"><img height="100px" alt="awsdeveloperbadge" src={props.img}/></a>
        </Tooltip>
    );
}

export default withStyles(style)(AWSCertificate);