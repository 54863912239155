import React from 'react';
import SiteFramework from '../SiteFramework';
import LegalNoticeContent from './LegalNoticeContent';


const LegalNotice = () => {
    return (
        <SiteFramework content={<LegalNoticeContent/>}></SiteFramework>
    );
}

export default LegalNotice;