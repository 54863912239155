import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "../../common/GridContainer";
import GridItem from "../../common/GridItem";

import introStyle from "./IntroContentStyle";
import { WithStyles } from "@material-ui/styles";
import Card from "../../common/Card";
import { CardHeader } from "semantic-ui-react";
import CardBody from "../../common/CardBody";


interface IContent extends WithStyles<typeof introStyle> { }
const IntroContent: React.FC<IContent> = ({ classes }) => {
  return (
    <div className={classes.section}>
      
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card plain>
              <CardHeader>
              <h2 className={classes.title}>About the name devARC</h2>
              </CardHeader>
              <CardBody>
              <p className={classes.description}>
          The abbreviation devARC is derived from the phrase "developing architectures". It takes into account that software and its architecture are seldomly designed from ground up. Instead, most software takes years to evolve, but with the right measures you can organize and structure this evolutionary process.
          </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    </div>
    </div>);
};

export default withStyles(introStyle)(IntroContent);