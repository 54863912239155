import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import ChatIcon from "@material-ui/icons/Chat";
import ProfileIcon from "@material-ui/icons/Portrait";
import PrivacyIcon from "@material-ui/icons/Fingerprint";
import { Icon } from '@iconify/react';
import lawIcon from '@iconify/icons-octicon/law';
import offerIcon from '@iconify/icons-mdi/offer';

import headerLinksStyle from "./HeaderLinksStyle";
import HeaderButton from "./HeaderButton";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <HeaderButton id="offerings" title="Offerings" icon={<Icon width={24} height={24} icon={offerIcon}/>} link="/offerings"></HeaderButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderButton id="about" title="About me" icon={<ProfileIcon/>} link="/profile"></HeaderButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderButton id="linkedin" title="Follow me on LinkedIn" icon={<LinkedInIcon/>} link="/linkedin"></HeaderButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderButton id="github" title="Follow me on GitHub" icon={<GitHubIcon/>} link="/github"></HeaderButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderButton id="contact" title="Contact" icon={<ChatIcon/>} link="/contact"></HeaderButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderButton id="privacy" title="Privacy Statement" icon={<PrivacyIcon/>} link="/privacy"></HeaderButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <HeaderButton id="legal" title="Legal Notice" icon={<Icon width={24} height={24} icon={lawIcon}/>} link="/legal"></HeaderButton>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
